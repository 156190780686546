import { z } from 'zod'
import { BigNumber } from 'bignumber.js'
import type { StatusFees } from './status'
import { getSynthSaveAssetId } from './main'
import type { ChainWallet } from '~/wallets/swapkit'
import type { SwapkitWalletStore } from '~/store/swapkitWalletStore'

export const swapActionNames = {
  fetchingQuote: 'Loading quote...',
  quoteError: 'No route available',
  canSwap: 'Swap',
  estimatingNetworkFee: 'Estimating network fee...',
  swapping: 'Signing & sending...',
  confirmingSwap: 'Confirming swap...',
  checkApproval: 'Check approval',
  checkingApproval: 'Checking approval...',
  estimatingApprovalFee: 'Estimating approval fee...',
  shouldApprove: 'Approve',
  confirmingApproval: 'Approve',
  approving: 'Approving...',
  success: 'Success',
  trackingApproval: 'Tracking approval...',
} as const satisfies { [K in string]: string }
export type SwapStatus = keyof typeof swapActionNames
export const isSwapLoading = (status: SwapStatus) => {
  const loadingStatues: SwapStatus[] = [
    'fetchingQuote',
    'swapping',
    'checkingApproval',
    'approving',
    'estimatingNetworkFee',
    'estimatingApprovalFee',
    'trackingApproval',
  ]
  return loadingStatues.includes(status)
}
export const isSwapDisabled = (status: SwapStatus) => {
  return isSwapLoading(status) || status === 'quoteError'
}

const emptyAmountSchema = z.literal('')
const zeroSchema = z.literal('0')
const nonZeroIntegerSchema = z
  .string()
  .regex(/^[1-9][0-9]*$/, 'Values greater than or equal to "1" may not start with "0"')
/**
 *
 * @param maxDecimals 1 or more
 */
const createFloatSchema = (maxDecimals: number) =>
  z
    .string()
    .regex(
      new RegExp(`^[0-9]+\\.[0-9]{0,${maxDecimals}}$`),
      'Floating point numbers may not start with "."',
    )
export const createAmountSchema = (maxDecimals: number) =>
  z.union([emptyAmountSchema, zeroSchema, nonZeroIntegerSchema, createFloatSchema(maxDecimals)])

export type WithNetworkFee = { networkUsd: number }
export type Fees = Pick<StatusFees, 'affiliateUsd' | 'protocolUsd'> & WithNetworkFee
export type FeesDisplay = StatusFees & WithNetworkFee

export const getNegativePriceImpactPercentage = ({
  outValueUsd,
  valueIn,
}: {
  valueIn: BigNumber.Value
  outValueUsd: BigNumber.Value
}) => {
  const decimalMultiplier = 10 ** 2
  return valueIn === 0
    ? BigNumber(0)
    : BigNumber(100)
        .minus(BigNumber(outValueUsd).multipliedBy(100).div(valueIn))
        .multipliedBy(decimalMultiplier)
        .integerValue(BigNumber.ROUND_FLOOR)
        .div(decimalMultiplier)
        .multipliedBy(-1)
}
export const getBalance = ({
  assetId,
  chain,
  wallets,
}: {
  assetId: string
  chain: string
  wallets: SwapkitWalletStore['wallets']
}) => {
  const walletsRecord: { [chain in string]?: ChainWallet } = wallets
  const assetIn = walletsRecord[chain]?.balance.find(
    ({ chain, symbol, ticker, address }) =>
      getSynthSaveAssetId({ chain, symbol, ticker, address }) === assetId,
  )
  return assetIn?.getValue('number') ?? 0
}
export const doesAmountExceedMax = ({
  amount,
  isGasAsset,
  isWalletConnected,
  maxAmount,
}: {
  amount: number
  isGasAsset: boolean
  isWalletConnected: boolean
  maxAmount: number
}) => {
  return isWalletConnected && isGasAsset && !Number.isNaN(amount) && amount > maxAmount
}
export const isAboveCCThreshold = ({
  CCBalance,
  expectedOutput,
  threshold,
}: {
  CCBalance: number
  expectedOutput: BigNumber | undefined
  threshold: number
}) => {
  return (
    !!expectedOutput && !Number.isNaN(threshold) && expectedOutput.plus(CCBalance).gte(threshold)
  )
}
